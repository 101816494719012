<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="예">예</b-form-radio>
              <b-form-radio value="아니오">아니오</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '아니오' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label }}</label>
            <b-form-input type="text" v-model="q.answer[1].data" class="input-lg" />
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q42',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '의료기관 방문 의사',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '의료기관 방문 의사',
        answer: [
          {
            data: '',
          },
          {
            label: '이유',
            data: '',
          },
        ],
      };
    }
    if (this.q.answer[0].data === '예') this.q.answer[1].data = ''; // '예' 선택시 '아니오' 하위의 질문 답변 초기화
  },
};
</script>